<template>
	<div>
	<ContentHeader title="Data Transaksi Perawatan" />
     <div class="content-header">
		<div class="container-fluid">
			<div class="card">
              <div class="card-header">
                <h3 class="card-title">Data Transaksi Perawatan</h3>

                <div class="card-tools">
                  <button type="button" class="btn btn-tool" data-card-widget="collapse" title="Collapse">
                    <i class="fas fa-minus"></i>
                  </button>
                </div>
              </div>
              <div class="card-body" style="display: block;">
                <div class="row align-items-center mt-2">
                    <div class="col-lg-2">
                      <span>Jenis Periode</span>
                      <select class="custom-select form-control" v-model="filter.jenis_periode" @change="getTransaksiPerawatan">
                        <option value="tanggal">Tanggal</option>
                        <!-- <option value="minggu">Minggu</option> -->
                        <option value="bulan">Bulan</option>
                        <option value="tahun">Tahun</option>
                      </select>
                    </div>
                    <div class="col-lg-2">
                      <span>Tanggal</span>
                      <input type="date" name="" v-model="filter.tanggal" class="form-control" @change="getTransaksiPerawatan">
                    </div>
                    <div class="col-lg-4"></div>
                    <div class="col-lg-4">
                      <span>Cari Data</span>
                      <input type="text" class="form-control" v-model="filter.kata_kunci" @keyup="getTransaksiPerawatan">
                    </div>
                </div>
                <table class="table table-bordered table-hover mt-4">
                  <thead>
                    <tr>
                      <th scope="col" class="text-sm">NOMOR KWITANSI</th>
                      <th scope="col" class="text-sm">TGL</th>
                      <th scope="col" class="text-sm">PASIEN</th>
                      <th scope="col" class="text-sm">DOKTER</th>
                      <th scope="col" class="text-sm">POLI</th>
                      <th scope="col" class="text-sm">LAYANAN</th>
                      <th scope="col" class="text-sm">TOTAL</th>
                      <th scope="col" class="text-sm">ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="row in transaksi_perawatan" :key="row.id">
                        <td>{{ row.nomor_kwitansi }}</td>
                        <td>{{ format_tanggal(row.created_at) }}</td>
                        <td>{{ row.nomor_rekam_medis }} - {{ row.nama_pasien }}</td>
                        <td>{{ row.nama_dokter }}</td>
                        <td>{{ row.kunjungan?.poli?.nama }}</td>
                        <td>{{ row.kunjungan?.layanan?.nama }}</td>
                        <td>{{ format_nominal(row.total_bayar) }}</td>
                        <td class="text-sm">
                          <div class="btn-group">
                              <button type="button" class="btn btn-sm btn-info text-sm">Proses</button>
                              <button type="button" class="btn btn-sm btn-info dropdown-toggle dropdown-icon" data-toggle="dropdown" aria-expanded="false">
                                <span class="sr-only"></span>
                              </button>
                              <div class="dropdown-menu" role="menu" style="">
                                <router-link :to="`/print-transaksi-perawatan/${row.id}`" class="dropdown-item text-sm">Print Struk</router-link>
                                <button @click="konfirmasiHapus(row.id)" class="dropdown-item text-danger" v-if="level_jabatan != 'staff'">Hapus</button>
                              </div>
                            </div>
                        </td>
                    </tr>
                  </tbody>
                </table>
              <p class="text-center mt-2" v-if="memuat_data"> Memuat Data... </p>
              <p class="text-center mt-2" v-if="cek_data"> Data Masih Kosong </p>
              </div>
              <!-- /.card-body -->
              <div class="card-footer" style="display: block;">
                
              </div>
              <!-- /.card-footer-->
            </div>
		</div>
	</div>
    </div>
</template>

<script setup>

import ContentHeader from '@/components/ContentHeader'

import axios from 'axios'
import { ref, computed, onMounted, reactive } from 'vue'
import store from '@/store'
import Swal from 'sweetalert2'

import format_tanggal from '@/format_tanggal.js'
import format_nominal from '@/format_nominal.js'
import * as dayjs from 'dayjs'

const date = new Date()

const user                = computed(() => store.getters['auth/user'])
const cabang                = computed(() => store.getters['auth/cabang'])
const level_jabatan       = computed(() => store.getters['auth/level_jabatan'])
const transaksi_perawatan = ref([])
const memuat_data         = ref(true)
const cek_data            = ref(false)
const filter              = reactive({
  jenis_periode : null,
  tanggal       : null,
  kata_kunci    : null
})


const getTransaksiPerawatan = async () => {
    transaksi_perawatan.value = []
    memuat_data.value = true
    cek_data.value = false

    let tanggal = ''

    if(filter.jenis_periode == 'tahun') {
      tanggal = filter.tanggal ? dayjs(filter.tanggal).format('YYYY') : dayjs(date).format('YYYY')
    } else if(filter.jenis_periode == 'bulan') {
      tanggal = filter.tanggal ? dayjs(filter.tanggal).format('YYYY-MM') : dayjs(date).format('YYYY-MM')
    } else {
      tanggal = filter.tanggal ? dayjs(filter.tanggal).format('YYYY-MM-DD') : dayjs(date).format('YYYY-MM-DD')
    }

    let kata_kunci = filter.kata_kunci ? filter.kata_kunci : 'emptyParameter'

    let { data } = await axios.get(`api/transaksi_perawatan/get/${user.value.cabang_id}/${tanggal}/${kata_kunci}`)

    if (data == 'kosong') {
      cek_data.value = true
      memuat_data.value = false
      transaksi_perawatan.value = []
    } else {
      cek_data.value = false
      memuat_data.value = false
      transaksi_perawatan.value = data
    }

}

const konfirmasiHapus = (id) => {
      Swal.fire({
        title: 'Hapus Data?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `Ya`,
        cancelButtonText: `Batal`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          hapusDataTransaksi(id)
        } 
      })
    }

  const hapusDataTransaksi = (id) => {
    axios.delete(`api/transaksi_perawatan/destroy/${cabang.value.tipe}/${id}`)
    .then((response) => {
      Swal.fire({
        title: 'Berhasil!',
        text: 'Berhasil Menghapus Data',
        icon: 'success',
        showConfirmButton: false,
        timer: 1500
      })
      console.log(response.data)
      getTransaksiPerawatan()
    })
    .catch((error) => {
      Swal.fire({
        title: 'Gagal!',
        text: 'Gagal menghapus data',
        icon: 'success',
        confirmButtonText: 'Ok'
      })
      console.log(error)
    })

  }

onMounted(() => {
    getTransaksiPerawatan()
})
</script>